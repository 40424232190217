<template>
  <div class="row">
    <div class="col-md-12">

      <div v-if="isLoaded && news_articles.length == 0" class="div_front">
        <vnud-card class="card card-article" :noFooterLine="true">
          <template v-slot:header>
            <div v-if="isPortfolioPage">
              You don't have anything in your portfolio.
              <br>
              <br>
              <i> Search and watch a company. </i>
            </div>
            <div v-else>
              No news found.
            </div>
          </template>
        </vnud-card>
      </div>

      <div class="div_front">
        <span v-if="user.my_debug_interface" class="pull-right span_white">
          <a :href="getDebugApiEntry(apiCallFinal)" target="_blank">
            &nbsp;&nbsp;<i class="fa fa-gear"></i> OPEN API</a>
        </span>
        <div class="clearfix" />
      </div>
      <div v-if="news_articles.length > 0">
        <vnud-card class="card card-article" v-for="news in news_articles " :key="news" inverted
          :badge-type="getBadgeClassType(news.sentiment_score)" :badge-icon="getBadgeNowIcon(news.sentiment_score)"
          :noFooterLine="true">

          <template v-slot:header>

            <span class="pull-right image-padding" v-html="getImage(news)"></span>
            <span class="pull-right" v-if="news.experiment"><small>{{ news.experiment }}</small></span>

            <span :class="getBadgeClass(news.sentiment_score) + ' news_link'" @click="openLink(news)">

              <span v-if="news.AI?.defcon_level < 3 && news.related_exchange_tickers?.length <= 3">
                <span v-if="news.AI?.defcon_outcome == 'positive'">
                  <i v-if="news.AI?.defcon_level == 1" class="fas fa-2x fa-star-exclamation siren-on" />
                  <i v-if="news.AI?.defcon_level == 2" class="fal fa-2x fa-star" />
                </span>
                <span v-else>
                  <i v-if="news.AI?.defcon_level == 1" class="fas fa-2x fa-bomb siren-on" />
                  <i v-if="news.AI?.defcon_level == 2" class="fal fa-2x fa-bomb" />
                </span>
              </span>
              <span v-else>
                <i class="fad fa-newspaper"></i>&nbsp;
              </span>

              <span class="" v-html="getTitle(news)">
              </span>

            </span>

            <div><br></div>

            <span v-if="news.tools || news.AI">
              <h5 class="title news_link" @click="openLinkComments(news)">
                <i class="fas fa-external-link float-left">&nbsp;</i>
                <!-- <i class='pull-right' v-if="news.AI && news.AI.sentiment" :class="'fal fa-' + getSentiment(news)">&nbsp;</i> -->
                <span v-html="getNoBullshit(news)"></span>
              </h5>
            </span>

            <p v-html="markdown(news.summary)">
            </p>

            <p v-if="!news.ai_summary">
              {{ news.articles[0] }}
            </p>

            <collapse v-if="news.ai_summary && news.summary != news.ai_summary">
              <collapse-item :id="'col_' + news.id">
                <template v-slot:title-raw>
                  <span class="read-more">
                    Read more...
                  </span>
                </template>
                <template v-slot>
                  <div class="">
                    <p v-html="markdown(news.ai_summary)">
                    </p>
                  </div>
                </template>
              </collapse-item>
            </collapse>

          </template>

          <template v-slot:footer>
            <div class="row" v-if="user.my_debug_interface">
                <NewsNer :newsId="news.id"/>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="pull-right" v-if="news.named_exchange_tickers">
                  <div class="pull-right" v-for="name, tickers in news.named_exchange_tickers " :key="tickers">
                    <div
                      class="local-margin-left">
                      <n-button @click="gotoTicker(tickers)" type="outline-primary" size="sm"><small>{{ name }}
                          <br><b>{{ tickers.replace("NMS:", "") }}</b></small></n-button>
                    </div>
                  </div>
                </div>
                <div class='top_padding div_timeago'>
                  <i v-if="news.AI?.article_icon || news.AI?.sentiment"
                    :class="'fal fa-' + getSentiment(news)">&nbsp;</i>
                  <small><b class="dark_color ">{{ timeAgo(news.creation_date) }}</b></small>

                  <router-link v-if="news.publisher" class="dark_link"
                    :to="'/news/publisher/' + news.publisher.replace(/ /g, '_')">
                    <small v-if="news.publisher">&nbsp; <i> by {{ news.publisher }}</i></small>
                  </router-link>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <StarRating v-if="news.interest_score > 0" :score="news.interest_score" class="" />

                <router-link v-if="news.AI?.classification" class="link footer-link"
                  :to="'/news/class/' + encodeURIComponent(news.AI.classification.replace(/ /g, '_'))">
                  <span class="pull-right"><small>{{ news.AI?.classification
                      }}</small></span>
                </router-link>

                <hr>
                <div class="action-items col-md-12">
                  <span class="action_item" :class="{ 'selected-class': currentCommentArticle === news.id }">
                    <a href="javascript:void(0)" @click="openCommentEditor(news)">
                      {{ news.no_comments }} Comments
                    </a>
                  </span>
                  <span class="action_item" :class="{ 'selected-class': currentShareArticle === news.id }">
                    <a href="javascript:void(0)" @click="openShareEditor(news.id)">
                      Share
                    </a>
                  </span>
                  <span class="action_item" :class="{ 'selected-class': currentReportArticle === news.id }">
                    <a href="javascript:void(0)" @click="openReportEditor(news.id)">
                      Report
                    </a>
                  </span>
                  <span class="" v-if="user.is_admin">
                    <CheckboxSmall :inline="true" v-model="news.is_blocked" @change="handleBlock(news)">Hide
                    </CheckboxSmall>
                  </span>
                </div>

                <span v-if="user.my_debug_interface" class="pull-right">

                  <a :href="getDebugApiEntry('/news/translate/' + news.id + '/es-ES')" target="_blank">
                    &nbsp;&nbsp;<i class="fal fa-globe"></i> Translate </a>

                  <a :href="getDebugApiEntry('/news/force_reindex/' + news.id)" target="_blank">
                    &nbsp;&nbsp;<i class="fa fa-refresh"></i> AI Reindex </a>

                  <a :href="getDebugApiEntry('/news/get/' + news.id)" target="_blank">
                    &nbsp;&nbsp;<i class="fa fa-gear"></i> Open API </a>

                </span>
                <div class="clearfix" />
              </div>
            </div>
            <SharePanel :news_id="news.id" :news_title="getTitleSharing(news)" v-if="currentShareArticle === news.id" />

            <div class="comment_editor" v-if="currentCommentArticle === news.id || currentReportArticle === news.id">
              <div v-if="currentReportArticle === news.id">
                <i class="fa fa-bug"></i> Specify what is the problem about this post.
              </div>

              <div v-if="user.is_valid">
                <QuillEditor :options="options" v-if="logged_in"
                  :toolbar="['bold', 'italic', 'strike', 'link', { 'list': 'ordered' }, { 'list': 'bullet' }]"
                  @textChange="handleTextChange" v-model:content="newCommentContent" contentType="html" theme="snow" />

              </div>
              <div v-else>
                <div class="alert alert-primary" v-if="logged_in">
                  <a :href="getValidateEmail()" style="color: white !important;">
                    <i class='fal fa-envelope'></i>
                    Please click here to validate you as human.
                  </a>
                </div>
              </div>

              <div v-if="!logged_in">
                <h6>
                  <router-link class="link footer-link" to="/register?comment=article">
                    <i class='fa fa-link'></i> Please click here to register and comment...
                  </router-link>
                </h6>
              </div>

              <n-button v-if="newCommentContent != ''" @click="submitComment(news.id)" type="default"
                size="sm">Submit</n-button>

              <n-button v-if="newCommentContent != ''" @click="cancelComment()" type="outline-default"
                size="sm">Cancel</n-button>

              <small v-if="user.my_debug_interface" class='pull-right'>{{ newCommentContent }}</small>

              <news-comments :article="news" v-if="currentCommentArticle === news.id">
              </news-comments>

            </div>
          </template>
        </vnud-card>
      </div>

      <div class="pagination-controls" v-if="isPagination">
        <n-button @click="loadPreviousPage" :disabled="currentPage === 1">Back</n-button>
        <n-button @click="loadNextPage" class="pull-right" :disabled="!hasMoreData">Next</n-button>
      </div>
      <div v-else>
        <br>
        <i v-if="!isLoaded" class="fa fa-2x fa-spinner fa-spin white" style='color:white'></i>
      </div>

    </div>
  </div>
</template>
<script>
import { useRoute } from 'vue-router';

import { TimeLine, MainItem, TimeLineItem, SharePanel } from "@/components";
import { Collapse, CollapseItem } from "@/components";

import NewsComments from "@/views/dashboard/comments/NewsComments.vue";
import NewsNer from "./NewsNer.vue"

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

import globalConfig from "@/globalConfig.js";
import globalController from "@/globalController.js";

import utils from "@/globalUtil.js";

import { mapState, mapActions } from "vuex";
import {
  CheckboxSmall, StarRating,
} from "@/components";

export default {
  components: {
    Collapse, CollapseItem,
    TimeLine,
    TimeLineItem,
    StarRating,
    SharePanel,
    MainItem,
    QuillEditor,
    NewsNer,
    CheckboxSmall,
    NewsComments,
  },
  props: {
    isPagination: {
      type: Boolean,
      default: false,
    },
    symbol: String,
    apicall: String,
    disable_load: Boolean,
  },
  watch: {
    apicall(newValue) {
      this.news_articles = [];
      this.loadData();
    },
    symbol(newValue) {
      this.news_articles = [];
      this.loadData();
    },
  },
  created() {
    this.loadData();
    if (!this.isPagination)
      window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    if (!this.isPagination)
      window.removeEventListener('scroll', this.handleScroll); // Clean up the event listener
  },
  updated() {
    //this.loadData();
  },
  data() {
    return {
      newCommentContent: "",
      options: {
        // TODO Add markdown instead of HTML https://github.com/aral/quill-markdown-shortcuts-for-vue-quill-editor

        // debug: 'info',
        toolbar: 'minimal',
        placeholder: 'Place a comment and remember to be human...',
        theme: 'snow',
      },
      currentPage: 0,
      hasMoreData: true,
      pageSize: 5,    // Number of items per page
      ticker: "",
      isLoaded: false,
      currentCommentArticle: null,
      currentShareArticle: null,
      currentReportArticle: null,
      news_articles: [],
      apiCallFinal: null,
    };
  },
  computed: {
    isPortfolioPage() {
      return (this.$route.path === '/news/my_portfolio')
    },
    ...mapState({
      user: (state) => state.user,
      logged_in: (state) => state.logged_in,
    }),
  },
  methods: {
    getValidateEmail() {
      return globalConfig.getApiPath("/user/validate_email");
    },

    getSentiment(news) {
      let n = news?.AI?.article_icon;
      if (!n) {
        n = news?.AI?.sentiment;
      }

      if (!n) {
        return "";
      }

      try {
        // Sometimes AI will give as an ARRAY and crash here.
        if (n instanceof Array)
          n = n[0];

        // Skip things that the AI is doing like adding a :
        n = n.replace(/:/g, "");
      } catch (e) {
        return "";
      }

      if (n == "neutral")
        n = "meh";
      if (n == "negative")
        n = "angry";
      if (n == "positive")
        n = "smile";
      return n;
    },
    handleBlock(article) {
      console.log(" HANDLE BLOCK " + article.is_blocked);
      globalController.api_set_property("/news", article.id, "is_blocked", article.is_blocked,
        (data) => {
        },
        (error) => {
          console.log(" ERROR " + error);
          utils.runToast(` ${error} `, "now-ui-icons ui-1_bell-53", "bottom-right", "danger");
        });
    },
    openViewComments(article) {
      article.comments_opened = !article.comments_opened;
    },
    timeAgo(datetime) { return utils.timeAgo(datetime) },
    getTitleSharing(article) {
      let title = this.getClickBaitTitle(article);
      if (!title)
        title = this.getTitle(article);
      // Remove HTML tags;
      return title.replace(/<[^>]*>/g, '');
    },
    handleTextChange(delta, oldDelta, source) {
      console.log("Text changed:", delta);
      console.log("Old content:", oldDelta);
      console.log("Source of change:", source);
    },
    getDebugApiEntry(apiCall) {
      return globalConfig.getApiPath(apiCall);
    },
    resetActions() {
      this.currentCommentArticle = null;
      this.currentShareArticle = null;
      this.currentReportArticle = null;
    },
    cancelComment() {
      this.resetActions();
    },
    openShareEditor(articleId) {
      this.resetActions();
      this.currentShareArticle = articleId;
      console.log(" SHARE OPEN ");
    },
    openReportEditor(articleId) {
      if (!this.logged_in) {
        this.$router.push("/register?source=report");
        return;
      }

      this.resetActions();
      this.currentReportArticle = articleId;
      console.log(" REPORT OPEN ");
    },
    openCommentEditor(article) {
      // Set the current article ID to show the editor for that article only
      if (this.currentCommentArticle == article.id) {
        this.resetActions();
        return;
      }

      this.resetActions();
      this.newCommentContent = ''; // Reset comment content each time
      this.currentCommentArticle = article.id;
      this.openViewComments(article);
    },
    submitComment(articleId) {
      // Logic to post the comment content to the API or handle locally

      let my_comment = {
        'parent_obj': 'news',
        'parent_obj_id': articleId,
        'content': this.newCommentContent,
      };

      if (this.currentReportArticle === articleId) {
        my_comment['is_report'] = true;
      }

      console.log(`Submitting comment for article ${articleId}:`, this.newCommentContent);
      globalController.save_json_update(
        '/comments/create',
        my_comment,
        (data) => {

          if (data.status == "success") {
            utils.runToast(
              ` Saved `,
              "now-ui-icons ui-1_bell-53",
              "bottom-right",
              "info"
            );

            this.currentCommentArticle = articleId;
            return
          }

          utils.runToast(
            ` Failed uploading comment ${data.error_msg} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        },
        (error) => {
          utils.runToast(
            ` ${error} `,
            "now-ui-icons ui-1_bell-53",
            "bottom-right",
            "danger"
          );
        }
      )

      // Here, you would send `this.newCommentContent` to the API or handle it as needed
      // After submission, clear the editor
      this.currentCommentArticle = null;
      this.newCommentContent = '';
    },
    handleScroll() {
      // Only load when we are already loaded
      if (!this.isLoaded)
        return;

      if (this.disable_load)
        return;

      const scrollY = window.scrollY || window.pageYOffset;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;

      // If the user is within 300px of the bottom of the page and more data is available, load next page    
      if (scrollY + visible >= pageHeight - 300 && this.hasMoreData) {
        this.currentPage++;
        this.loadData(); // Load the next page
      }
    },
    postNewComment() {
      this.isPostComment = true;
    },

    loadPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.loadData();
      }
    },

    // Logic to load the next page
    loadNextPage() {
      if (this.hasMoreData) {
        this.currentPage++;
        this.loadData();
      }
    },
    gotoTicker(ticker) {
      this.$router.push(globalController.get_route_ticker(ticker));
    },
    getSentimentScore(scores) {
      // Check if scores is a ProxyArray or regular array and has at least one element
      if (Array.isArray(scores) && scores.length > 0) {
        return scores[0];
      }
      return 0; // Default value if the array is empty or not valid
    },
    getScoreFromArray(score_array) {
      let score = 0;
      try {
        score = score_array[0];
      }
      catch (err) {
        console.log(err);
      }

      return score;
    },
    openLinkComments(article) {
      this.$router.push(globalController.get_news_link({ 'id': article.id, 'title': this.getNoBullshit(article) }))
    },
    openLink(article) {
      window.open(article.link);
    },
    cleanTitle(title) {
      // Split the title into an array of words      
      if (title.toLowerCase().includes('cookies'))
        return "---";

      const words = title.split(' ');

      // If the title has more than 10 words, slice the first 10 and append "..."
      if (words.length > 10) {
        return words.slice(0, 10).join(' ') + '...';
      }

      // If the title has 10 words or less, return it as is
      return title
    },
    getImage(article) {
      try {
        let gif_url = article.AI?.gif_keywords;

        /*
        if (article.AI?.defcon_level == 1) {
          gif_url="breaking news";
        }
        */

        if (!gif_url)
          return;

        return `
        <div style='border-radius: 12px; overflow: hidden; '>
          <video style='max-height: 180px;' width="180" autoplay loop muted playsinline onerror="this.remove();">
            <source src="/api/news/gif?keywords=${gif_url}" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>`;

        //return "<img width='164px' src='/api/news/gif?keywords=" + gif_url + "'  onerror='this.remove();'>"
      } catch (e) {

      }

      return "";
    },
    getTitle(article) {
      try {
        let title = article.source_title || article.AI?.title || article.title;
        if (title.length > 0)
          return this.cleanTitle(title);

      } catch (e) {
      }
      return "--"
    },
    capitalizeFirstLetter(string) {
      if (!string) return '';
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    getClickBaitTitle(article) {
      try {
        if (article.AI?.title_clickbait)
          return this.markdown(article.AI?.title_clickbait);
      } catch {
      }
      return this.getNoBullshit();
    },
    getNoBullshit(article) {
      // This is bullshit!!!      
      try {
        if (this.user.my_clickbait && article.AI?.title_clickbait)
          return this.markdown(article.AI?.title_clickbait);

        // General no bullshit title       
        let no_bullshit = article.no_bullshit || article.AI?.no_bullshit || article.title;
        if (no_bullshit) {
          // Remove 

          // Remove all the stuff that is not needed, things that the AI says which it repeats all the time

          const patterns = [
            /\bTL;DR[\s;:-]*/gi, /Get to the point.*?[,.!:]/gi,
            /Cutting .*?[,.!:]/gi, /The bottom.*?[,.!:]/gi,
            /here's the .*?[,.!:]/gi, /To cut to.*?[,.!:]/gi,
            /Let's.*?[,.!:]/gi, /folks.*?[,.!:]/gi, /Translation.*?[,.!:]/gi,
            /No more b.*?[,.!:]/gi, /Don't be a.*?[,.!:]/gi,
          ];

          no_bullshit = no_bullshit.replace(
            new RegExp(patterns.map(p => p.source).join('|'), 'gi'),
            ''
          );

          no_bullshit = no_bullshit.trim();

          if (!no_bullshit)
            return this.getTitle(article);

          return this.markdown(this.capitalizeFirstLetter(no_bullshit));
        }
      } catch (e) {
      }

      // What? Nothing yet? Crazy, try to get the source title.
      return this.markdown(this.getTitle(article));
    },
    getBadgeClassType(score_array) {
      if (!score_array)
        return 'success';

      let score = this.getScoreFromArray(score_array);
      if (score <= -5) {
        return 'danger';
      } else if (score > -5 && score < 0) {
        return 'warning';
      } else if (score === 0) {
        return 'info';
      } else if (score > 0 && score < 5) {
        return 'primary';
      }

      return 'info';
    },
    getBadgeClass(score_array) {
      return ' ' + this.getBadgeClassType(score_array);
    },
    getBadgeIcon(score_array) {
      if (!score_array)
        return 'business_briefcase-24';
      let score = this.getScoreFromArray(score_array);

      if (score == 0) return 'business_briefcase-24';

      if (score < -7) return 'sport_user-run';
      if (score < -5) return 'design_scissors';
      if (score < 0) return 'objects_umbrella-13';
      if (score > 1) return 'ui-2_like';
      if (score > 3) return 'sport_trophy';
      if (score > 5) return 'ui-2_favourite-28';
      if (score > 7) return 'objects_diamond';

      return 'business_briefcase-24';
    },
    getBadgeNowIcon(score_array) {
      return "now-ui-icons " + this.getBadgeIcon(score_array);
    },
    markdown(src) {
      if (!src)
        return "";

      return utils.markdown(src);
    },
    processArticles(news) {

      let first_article = false;
      news.forEach((article) => {
        // Check for summary in tools
        try {
          const queryParams = this.$route.query;

          // ADDED NER TO DISPLAY, We will integrate this into the backend once we cantrust the NER engine
          if (article.NER) {
            if (!article.named_exchange_tickers)
              article.named_exchange_tickers = {}
        
            for (const [key, value] of Object.entries(article.NER)) {
              article.named_exchange_tickers[value.tickers[0]] = value.name;
            }
          }

          console.log(" LOAAAAAAAAAAAAAAAAD ");
          if (!first_article && (queryParams.id || queryParams.symbol)) {
            const descriptionTag = document.querySelector('meta[name="description"]');
            if (descriptionTag) {
              descriptionTag.setAttribute('content', article.AI?.paragraph || article.AI?.summary);
            }
            if (article.AI?.title_clickbait)
              document.title = article.AI?.title_clickbait;

            first_article = true;
          }

          if (article.AI?.summary) {
            article.summary = utils.htmlToMarkdown(article.AI.summary);
            return
          }

          if (article.AI?.paragraph) {
            article.summary = utils.htmlToMarkdown(article.AI.paragraph);
            return
          }

          if (article.summary) {
            article.summary = utils.htmlToMarkdown(article.summary);
            return
          }


        } catch {
          // We might not have the right argument
        }

        // Check for AI summary if tools summary doesn't exist
        if (article.ai_summary) {
          article.summary = utils.htmlToMarkdown(article.ai_summary);
          return;
        }

        // Fallback to joining articles if available
        if (article.articles && article.articles.length > 0) {
          article.summary = utils.htmlToMarkdown(article.articles.join("\n\n")); // Join articles with paragraph breaks
          return;
        }
      });
      return news;
    },
    nothingHere() {
      if (this.$route.path === '/news/my_portfolio')
        this.$router.push("/company/explore?nothing_here=1");
    },
    async loadData(company_name) {

      this.isLoaded = false;
      const queryParams = this.$route.query;

      let apiCall = "";

      console.log(" LOAD NEWS DATA ");

      const currentScrollPosition = window.scrollY || window.pageYOffset;

      // Does the component specify an API call?
      if (this.apicall) {
        apiCall = this.apicall;
        console.log(" LOAD API CALL " + apiCall);
      } else {
        apiCall = "/news/query?ai_summary__ne=NULL&order_by=-creation_date"; // &creation_date__gte=1%20day&

        if (this.symbol) {
          apiCall = "/news/company/" + this.symbol + "?";
          //apiCall += "&related_exchange_tickers__iendswith=:" + simp;
        }
        else
          if (queryParams.symbol) {
            let simp = queryParams.symbol.split(":")[1]
            apiCall += "&related_exchange_tickers__iendswith=:" + simp;
          }
          else
            if (queryParams.id) {
              apiCall = "/news/query?id=" + encodeURIComponent(queryParams.id);
              console.log(" LOAD API NEWS ID " + queryParams.id);
            } else {
              let l = apiCall.length;


              // We don't have anything to load
              if (apiCall.length == l)
                return;
            }
      }

      // Don't ignore experiments if we specifically say so.
      if (queryParams.experiment) {
        apiCall = apiCall.replace("experiment__ne=NULL", '');
        apiCall += "&experiment=" + queryParams.experiment;
      } else
        if (!apiCall.includes('experiment__'))
          apiCall += "&experiment=NULL";

      apiCall += `&skip=${this.currentPage * this.pageSize}&limit=${this.pageSize}`;

      let page = this.currentPage;

      this.apiCallFinal = apiCall;
      console.log(" API CALL " + apiCall);

      globalController.api_call(
        apiCall,
        (result) => {
          if (result.news.length == 0) {

            if (this.isPagination) {
              this.news_articles = [];
              this.isLoaded = true;
            } else
              this.hasMoreData = false;

            if (this.news_articles.length == 0)
              this.nothingHere();

          } else {

            this.processArticles(result.news);
            if (this.isPagination)
              this.news_articles = result.news;
            else
              this.news_articles = [...this.news_articles, ...result.news];
          }

          this.isLoaded = true;

          console.log("News result: Result " + page);
        },
        (error) => {
          if (this.isPortfolioPage)
            this.isLoaded = true;

          console.log("Failed loading: Error loading suggestion");
          if (this.user.my_debug_interface)
            utils.runToast(
              ` ${error} `,
              "now-ui-icons ui-1_bell-53",
              "bottom-right",
              "danger"
            );
        }
      );
    },
  }
};
</script>
<style>
.loading-spinner {
  display: flex;
  justify-content: center;
  padding: 20px;
}

.image-padding {
  padding-left: 10px;
}

.action-items {
  display: flex;
  gap: 16px;
  /* Controls spacing between each action item */
}

.action-item {
  padding-left: 10px;
  /* Adjust as needed for spacing */
}

.action-item a {
  text-decoration: none;
  color: #007bff;
  /* Customize color as needed */
  cursor: pointer;
}

.action-item a:hover {
  text-decoration: underline;
}

.selected-class a {
  color: #007bff !important;
}

.div_front {
  z-index: 9000;
  width: 100%;
}

.span_white a {
  color: #fff !important;
}

.dark_color {
  color: #555 !important;
}

.top_padding {
  padding-top: 15px;
}

.news_link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.comment_editor {
  padding-top: 5px;
}

.div_timeago {
  min-width: 200px !important;
  overflow: hidden;
}

.card-article {
  padding: 4px;
  margin-top: 20px;
}

.card {
  max-width: 100vw;
  overflow: clip;
  /* add scrolling if content overflows */
}

.news_link {
  max-width: 100vw;
  overflow: clip;
  /* add scrolling if content overflows */
}

.local-margin-left {
  margin-left: 5px;
  margin-top: 5px
}

video {
  display: block;
}

.white {
  color: white;
}

@keyframes sirenToggle {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

.siren-on {
  animation: sirenToggle 2s infinite;
}

.siren-off {
  animation: sirenToggle 2s infinite;
  animation-delay: 1s;
  /* Starts the second icon after the first */
}

.siren-positive {
  color: #58e4ac;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(116, 116, 116);
}

.siren-negative {
  color: red;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(126, 126, 126);
}

.siren-alert {
  position: absolute;
  top: 10px;
  left: 10px;
}
</style>
